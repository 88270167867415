<script setup lang="ts">
import { isTypePage } from "~/types/contentful";

const contentConfig = useContentConfig();

const page = useLoadedContent();

const metaTitle = computed(() => {
  let title;

  if (page.value) {
    if (isTypePage(page.value)) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      title = page.value.fields.metaTitle || page.value.fields.title;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return title || page.value?.fields.slug || "";
});

const metaTitleBase = computed(() =>
  page.value
  && ((isTypePage(page.value) && !page.value.fields.hideMetaTitleBase)
    || !isTypePage(page.value))
    ? contentConfig.value?.metaTitleBase
    : "",
);

useHead({
  title: metaTitle,
  ...(metaTitleBase.value ? {} : { titleTemplate: null }),
});
</script>

<template>
  <div class="simplified">
    <AppHeader />
    <main>
      <slot />
    </main>
    <AppFooter class="appfooter" />
  </div>
</template>

<style lang="scss" scoped>
.appfooter:deep(.block-newsletter) {
  display: none;
}

.simplified:deep(.block-contact) {
  @apply pt-6;

  .bc-breadcrumbs {
    @apply hidden
  }

  h1 {
    @apply mb-4
  }

  h3 {
    @apply font-normal mt-5 mb-3;
  }

  ul li p {
    @apply relative pl-3;

    &::before {
      content: "•";

      @apply absolute left-0;
    }
  }
}
</style>
